@use "src/theme" as *;

.button {
  position: relative;
  padding: 4px;
  background-color: $primary;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  font-family: $fontFamily;
  height: 40px;
  outline: none;
  text-transform: capitalize;
  transition: all ease-in-out;
  white-space: nowrap;
  width: 100%;

  &:disabled {
    background-color: lightgrey;
    color: $dark;
    cursor: default;
    opacity: 0.8;
  }
  &:hover:enabled,
  &:focus {
    background-color: $primaryHover;
  }
  &:focus {
    box-shadow: none !important;
  }
}
