//Color Palette
$primary: #f8b431;
$primaryHover: #e99e14;
$secondary: #471700;
$tertiary: #f57c36;
$error: #f54336;
$light: #ffffff;
$dark: #343740;
$black: #000;
$gray: #c5c4c4;
$darkgray: #707070;
$shadow: rgba(0, 0, 0, 35%);

//Typography
$fontFamily: "Open Sans", "Arial", sans-serif;
$fontHeading1: 52px;
$fontHeading2: 34px;
$fontHeading3: 26px;
$fontHeading4: 17px;
$fontHeading5: 16px;
$fontBody: 14px;
$fontLabel: 12px;
$fontCaption: 10px;
