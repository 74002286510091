@use "src/theme" as *;

.label {
  @include flexColumn(center, center);
  width: 120px;

  @media screen and (max-width: 580px) {
    width: 60px;
    font-size: $fontLabel;

    .heading {
      font-size: $fontHeading2;
    }
  }
  @media screen and (max-width: 499px) {
    .h5 {
      font-size: $fontBody;
    }
  }
}

.countdown {
  @include flexColumn(center, center);
  background-color: $primary;
  box-shadow: 0px 8px 8px $shadow;
  border-radius: 0 0 24px 24px;
  color: $black;
  padding: 20px 32px;
  max-width: 1000px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  @media screen and (max-width: 1023px) {
    border-radius: 0 0 16px 16px;
    width: calc(100% - 48px);
    padding: 20px 12px;
  }

  .title {
    @media screen and (max-width: 500px) {
      font-size: $fontHeading3;
    }
  }

  .link {
    color: $black;
    &:hover,
    &:focus-visible {
      outline: none;
      margin-bottom: 0;
      color: rgba(0, 0, 0, 0.75);
    }
  }

  .time {
    @include flexRow(center, center, wrap);
    margin-top: 8px;
    @media screen and (max-width: 369px) {
      width: 80%;

      .h5 {
        font-size: $fontBody;
      }
    }

    label {
      @include flexRow($wrap: nowrap);
    }

    span {
      font-size: $fontHeading1;
      line-height: 72px;
      color: rgba(0, 0, 0, 0.4);
      padding-left: 8px;
      @media screen and (max-width: 580px) {
        font-size: $fontHeading2;
      }
    }
  }
}
