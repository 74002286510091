@use "src/theme" as *;

.navigation {
  @include flexRow(flex-start, center, wrap);
  min-height: 64px;
  @media screen and (max-width: 750px) {
    @include flexColumn();
    margin: 12px 0 0;
    width: 100%;
  }

  img {
    cursor: pointer;
    padding-top: 4px;
    height: 30px;
    width: 136px;
    @media screen and (max-width: 750px) {
      margin: 12px 0;
    }
  }

  .text {
    @include flexRow(flex-start, center, nowrap);
    @media screen and (max-width: 750px) {
      flex-wrap: wrap;
      margin-top: 12px;
      width: 100%;

      a {
        height: 40px;
        max-width: 114px;
      }
    }
  }

  .label {
    color: $primary;
    cursor: pointer;
    padding-right: 28px;
    @media screen and (min-width: 1000px) and (max-width: 1100px) {
      padding-right: 20px;
      font-size: 13px;
    }
  }

  .active {
    color: rgba($primary, 0.5);
  }
}

.footer {
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 750px) {
    flex-direction: column-reverse;
  }

  .active {
    color: $primary;
  }
}

.heading {
  @include flexRow(space-between, center, wrap);
  height: 64px;
  margin-bottom: 8px;
  padding-top: 4px;
  padding-left: 16px;
  width: 100%;
  @media screen and (max-width: 1000px) {
    height: auto;
  }

  .iconGroup {
    @include flexRow($alignY: center);
    padding-top: 2px;

    svg {
      cursor: pointer;
      height: 42px;
      margin-right: 8px;
      width: 42px;
    }

    @media screen and (max-width: 465px) {
      overflow-y: hidden;
      margin-right: 16px;
      flex-wrap: nowrap;

      svg {
        margin-right: 8px;
      }
    }
  }
}
