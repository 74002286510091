@use "src/theme" as *;

.anchor {
  cursor: pointer !important;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    outline: none;
    margin-bottom: 4px;
  }
}
