@use "src/theme" as *;

.container {
  @include flexColumn(center, center);
  margin: 12px auto;
  width: 100%;
}

.content {
  @include flexColumn();
  background-color: $secondary;
  border-radius: 12px;
  padding: 20px;
  margin: 12px auto;
  max-width: 1000px;
  min-height: 200px;
  position: relative;
  width: calc(100% - 40px);
}

.logoutContainer {
  @include flexColumn($alignX: flex-end);
  top: 32px;
  right: 32px;
  position: absolute;

  .logout {
    @include flexRow(center, center, nowrap);
    width: 120px;

    svg {
      width: 20px;
      height: auto;
      padding-right: 4px;
    }

    span {
      margin-bottom: 4px;
    }
  }
}

.loggedIn,
.featuredPack,
.singlePack {
  @include flexColumn();
  width: 100%;
}

.headersContainer {
  @include flexRow();
  margin-bottom: 12px;
  width: 100%;

  a:hover {
    margin-bottom: 0px;
  }

  .header {
    border-bottom: 2px transparent solid;
    color: $light;
    cursor: pointer;
    padding-bottom: 8px;
    margin-right: 36px;
    margin-left: 8px;
    padding: 0 4px;
    width: auto;
    &:hover {
      border-bottom-color: $light;
    }
  }

  .active {
    border-bottom-color: $primary;
    &:hover {
      border-bottom-color: $primary;
    }
  }
}

.form {
  @include flexColumn();
  margin: 12px;
  width: 100%;

  form {
    @include flexColumn();
    width: 100%;

    input {
      max-width: 300px;
    }
  }

  .buttons {
    @include flexRow(space-between, center, wrap);
    max-width: 320px;
    width: 100%;

    button {
      width: 150px;
    }
  }

  button {
    margin-top: 8px;
  }

  .invalid {
    color: $error;
    font-size: $fontLabel;
    margin-bottom: 4px;
    height: 0px;
    transition: height 0.1s ease-in-out;
    visibility: hidden;
  }

  .show {
    visibility: visible;
    height: 20px;
  }
}

.login {
  align-items: center;
  justify-content: center;
  margin: 12px auto 20px;
  max-width: 300px;
}

.movePack {
  @include flexColumn($alignX: flex-end);
  top: 32px;
  right: 164px;
  position: absolute;

  .move {
    @include flexRow(center, center, nowrap);
    width: 160px;

    svg {
      width: 20px;
      height: auto;
      padding-right: 4px;
    }
  }
}
