@use "src/theme" as *;

.form {
  @include flexColumn();
  margin: 12px;
  width: 100%;

  form {
    @include flexColumn();
    width: 100%;

    input {
      max-width: 300px;
    }
  }

  .buttons {
    @include flexRow(space-between, center, wrap);
    max-width: 320px;
    width: 100%;

    button {
      width: 150px;
    }

    svg {
      height: 32px;
      margin: 0 auto;
      width: auto;
    }
  }

  button {
    margin-top: 8px;
  }

  .invalid {
    color: $error;
    font-size: $fontLabel;
    margin-bottom: 4px;
    height: 0px;
    transition: height 0.1s ease-in-out;
    visibility: hidden;
  }

  .show {
    visibility: visible;
    height: 20px;
  }

  .imgInput {
    @include flexRow($wrap: nowrap);
    width: 100%;
    margin-bottom: 20px;

    .imgLabel {
      color: $light;
      max-width: 340px;
    }
  }

  .withBlend {
    @include flexRow($wrap: nowrap);
    color: $light;
    margin-bottom: 8px;

    input {
      margin-right: 8px;
    }
  }
}
