@use "src/theme" as *;

.container {
  @include flexColumn($alignX: center);
  background-color: $dark;
  height: 100%;
  min-height: 100vh;
  position: relative;
  margin: 0 auto;
  width: 100%;

  .bg {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    @media screen and (max-width: 768px) {
      object-fit: cover;
    }
  }

  .content {
    @include flexColumn($alignX: center);
    height: auto;
    margin-bottom: 64px;
    max-width: 1200px;
    width: 100%;
    z-index: 2;
  }
}

.comingsoon {
  border-radius: 24px;
  height: 496px;
  overflow: hidden;
  margin-top: 20px;
  width: calc(100% - 24px);
  @media screen and (max-width: 930px) {
    margin: 0 12px;
    height: auto;
  }

  svg {
    height: auto;
    @media screen and (min-width: 930px) {
      transform: translate(0, -60px);
      width: 100%;
    }
  }
}

.pastPacks {
  @include flexColumn(center, center);
  margin: 12px auto;
  width: 100%;
}

.links {
  @include flexRow($alignX: space-around);
  border-radius: 20px;
  background-color: $secondary;
  max-width: 768px;
  width: calc(100% - 32px);
  margin-bottom: 20px;
  height: 100px;
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 12px 0;
  }
}
