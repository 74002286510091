@use "src/theme" as *;

.footer {
  @include flexColumn();
  max-width: 1040px;
  width: 100%;
  margin-top: 20px;
  @media screen and (max-width: 1040px) {
    width: calc(100% - 40px);
  }

  .bottom {
    @include flexRow();
    font-size: $fontLabel;
    font-weight: 500;

    .copyright {
      color: $light;
      white-space: pre-line;
      max-width: 312px;
      padding-right: 80px;
      margin-bottom: 20px;
    }

    .links {
      @include flexColumn();

      a {
        color: $primary;
        &:hover,
        &:focus-visible {
          outline: none;
          margin-bottom: 0;
          color: rgba($primary, 0.6);
        }
      }
    }
  }
}
