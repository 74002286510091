@use "src/theme" as *;

.pack {
  @include flexRow($alignY:center);
  background-color: $primary;
  border-radius: 4px;
  font-weight: 500;
  margin: 12px 8px;
  max-width: 640px;
  min-width: 280px;
  padding: 12px;
  width: 100%;

  .artContainer {
    @include flexColumn(center, center);
    background-color: $light;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    height: 100px;
    margin-right: 16px;
    width: 240px;

    img {
      height: 100%;
      margin: 10px;
      max-width: 240px;
      width: auto;
    }
  }

  .rightSide{
    @include flexColumn();
  }

  .title {
    margin-bottom: 16px;
    text-align: center;
    width: auto;
  }

  .buttons {
    @include flexRow(center, center, wrap);
    height: 30px;
    width: auto;

    svg {
      cursor: pointer;
      height: 20px;
      margin-right: 8px;
      width: auto;
      &:hover {
        margin-top: 2px;
      }
    }

    a {
      @include flexColumn(center, center);
      svg {
        height: 22px;
      }
    }
  }
}
