@use "src/theme" as *;

.labeledIcon {
  @include flexRow(center, center, nowrap);
  cursor: pointer;
  width: 216px;
  @media screen and (max-width: 890px) {
    margin-right: 4px;
    width: auto;
  }
  @media screen and (max-width: 720px) {
    flex-wrap: wrap;
    margin: 0 8px 12px;
    width: 140px;
  }

  .text {
    @include flexColumn();
    color: $primary;

    label {
      color: $light;
      font-size: $fontLabel;
    }
  }
}

.smaller {
  justify-content: flex-start;
  padding-left: 8px;
  @media screen and (max-width: 720px) {
    justify-content: center;
    width: 100px;
  }
  @media screen and (max-width: 425px) {
    width: 80px;

    .h4 {
      font-size: $fontBody;
    }
    img {
      width: 80px;
      height: auto;
    }
  }
}
