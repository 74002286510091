@use "src/theme" as *;

.blendguideimg {
  display: flex;
  border-radius: 20px;
  height: auto;
  margin: 8px auto 40px;
  max-width: 768px;
  overflow: hidden;
  width: calc(100% - 40px);
  @media screen and (max-width: 1068px) {
    height: auto;
    width: calc(100% - 40px);
  }
}

.collapsible {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.35s ease-in-out;
}

.active {
  max-height: 1380px;
}
