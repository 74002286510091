@use "src/theme" as *;

@mixin format($size: $fontHeading1, $lineHeight: 72px) {
  font-size: $size;
  font-weight: 900;
  line-height: $lineHeight;
}

.default {
  width: 100%;
  white-space: pre-wrap;

  &.h1 {
    @include format();
  }

  &.h2 {
    @include format($fontHeading2, 44px);
  }

  &.h3 {
    @include format($fontHeading3, 32px);
  }

  &.h4 {
    @include format($fontHeading4, 24px);
  }

  &.h5 {
    @include format($fontHeading5, 22px);
  }
}

.uppercase {
  text-transform: uppercase;
}
