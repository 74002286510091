@use "src/theme" as *;

.inputBox {
  @include flexColumn($alignY: center);
  margin-bottom: 12px;
  width: 100%;

  .label {
    color: $light;
    margin-bottom: 4px;
    padding-left: 8px;
    width: auto;
  }

  input {
    border: none;
    border-radius: 4px;
    height: 28px;
    padding: 4px 8px;
    outline: none;
    width: calc(100% - 16px);
  }

  .invalid {
    color: $error;
    font-size: $fontLabel;
    margin-top: 4px;
    padding-left: 8px;
    height: 0px;
    transition: height 0.1s ease-in-out;
    visibility: hidden;
  }

  .show {
    visibility: visible;
    height: 20px;
  }

  .imgLabel {
    font-weight: 500;
    margin-top: 12px;
    padding-left: 8px;

    label {
      background-color: $primary;
      border-radius: 4px;
      color: $black;
      margin-right: 8px;
      padding: 8px;
    }

    span {
      color: $light;
    }
  }
}
