//App Shell
.app {
  display: flex;
  max-width: 100vw;
  cursor: context-menu;
}

//Body
body {
  background-color: white;
  color: #343740;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  min-width: 320px;
}

//Global classes
h1,
h2,
h3,
p {
  margin: 0;
}

p {
  color: #343740;
}

a {
  text-decoration: none;
  cursor: default;

  &:focus-visible {
    outline: 1px rgba(black, 0.4) dashed;
  }
}

img {
  flex-shrink: 0;
}
