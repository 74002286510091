@use "src/theme" as *;

.loading {
  @include flexColumn($alignX: center);
  background-color: $secondary;
  border-radius: 20px;
  height: 360px;
  margin-top: 40px;
  max-width: 1200px;
  padding-top: 40px;
  width: 100%;
  @media screen and (max-width: 1200px) {
    width: calc(100% - 24px);
  }
}

.form {
  margin-top: 20px;
  max-width: 400px;
  padding-top: 0;
}
