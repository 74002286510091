@use "src/theme" as *;

.pastPack {
  @include flexColumn();
  color: $black;
  margin-bottom: 12px;
  width: 100%;

  .packDisplay {
    width: 100%;
  }

  .pack {
    @include flexColumn(center, center);
    background-color: $primary;
    border-radius: 4px;
    font-weight: 500;
    margin: 12px;
    padding: 20px 12px;
    text-align: center;
    width: 280px;

    .artContainer {
      @include flexColumn(center, center);
      background-color: $light;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 4px;
      height: 110px;
      margin: 8px 12px 16px;
      width: 240px;

      img {
        height: 100%;
        margin: 10px;
        max-width: 240px;
        width: auto;
      }
    }

    .buttons {
      @include flexRow(center, center, wrap);
      height: 30px;
      width: 100%;

      svg {
        cursor: pointer;
        height: 20px;
        margin: 4px 8px;
        width: auto;
        &:hover {
          padding-top: 2px;
        }
      }

      a {
        @include flexColumn(center, center);
        svg {
          height: 22px;
        }
      }
    }
  }

  .addPack {
    @include flexRow(center, center);
    background-color: $primary;
    border-radius: 4px;
    cursor: pointer;
    color: $black;
    margin: 8px;
    max-width: 640px;
    min-width: 280px;
    padding: 12px;
    right: 0;
    width: 100%;
    &:hover {
      background-color: $primaryHover;
    }

    svg {
      fill: $black;
      height: 40x;
      margin-right: 8px;
      width: 40px;
    }
  }

  .saveSorting {
    @include flexColumn($alignX: flex-end);
    top: 32px;
    right: 164px;
    position: absolute;

    .move {
      @include flexRow(center, center, nowrap);
      width: 160px;

      svg {
        width: 20px;
        height: auto;
        padding-right: 4px;
      }
    }
  }
}
