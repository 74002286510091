@use "src/theme" as *;

@mixin background {
  background-color: $gray;
  border-radius: 24px 24px 0 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: top center;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  @media screen and (max-width: 1023px) {
    border-radius: 16px 16px 0 0;
  }
}

.banner {
  @include flexColumn(center, center);
  width: 100%;
  @media screen and (max-width: 930px) {
    padding: 0 12px;
  }

  .top {
    @include flexColumn(center, center);
    height: 480px;
    position: relative;
    width: 100%;
    @media screen and (max-width: 930px) {
      height: auto;
    }
  }

  .bg {
    @include background();
  }

  .logo {
    bottom: 64px;
    height: 64px;
    left: 56px;
    position: absolute;
    width: auto;
    @media screen and (max-width: 930px) {
      bottom: 24px;
      left: unset;
    }
    @media screen and (max-width: 760px) {
      width: 160px;
      height: auto;
    }
  }

  .art {
    position: absolute;
    left: 0;
    right: 0;
    height: auto;
    max-width: 84%;
    margin: auto auto;
    @media screen and (max-width: 930px) {
      margin: 40px 0 96px;
      position: static;
      z-index: 3;
    }
    @media screen and (max-width: 798px) {
      display: none;
    }
  }

  .mobileArt {
    display: none;
    @media screen and (max-width: 798px) {
      display: block;
      height: auto;
      margin: 40px 0 108px;
      width: 96%;
      z-index: 3;
    }
  }

  .bottom {
    @include flexRow(space-evenly, center, wrap);
    background-color: $secondary;
    border-radius: 0 0 24px 24px;
    margin-top: 8px;
    min-height: 104px;
    padding: 4px 40px;
    width: 100%;
    @media screen and (max-width: 1023px) {
      border-radius: 0 0 16px 16px;
      height: auto;
      padding: 0;
    }
    @media screen and (max-width: 550px) {
      justify-content: center;
      padding: 0 calc((100% - 320px) / 2);
    }
  }
}

.pack {
  @include flexRow($alignX: center);
  max-width: 1180px;
  margin-bottom: 20px;
  width: 80%;
  @media screen and (max-width: 1180px) {
    width: 96%;
  }
  @media screen and (max-width: 768px) {
    height: auto;
  }

  .left {
    @include flexRow($alignX: center, $alignY: center);
    height: 320px;
    position: relative;
    width: 100%;
    @media screen and (max-width: 1180px) {
      flex-direction: column-reverse;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 8px;
      padding: 20px;
      width: 100%;
      height: auto;
    }
  }

  .bg {
    @include background();
    border-radius: 24px;
    @media screen and (max-width: 1023px) {
      border-radius: 16px;
    }
  }

  .logo {
    height: auto;
    margin-top: 12px;
    padding-left: 40px;
    width: 210px;
    z-index: 3;
    @media screen and (max-width: 940px) {
      padding-left: 0;
    }
    @media screen and (max-width: 760px) {
      width: 160px;
    }
  }

  .art {
    margin-left: 20px;
    max-height: 316px;
    max-width: calc(100% - 280px);
    height: auto;
    object-fit: contain;
    z-index: 3;
    @media screen and (max-width: 940px) {
      height: auto;
      margin-left: 8px;
      max-height: 100%;
      width: 96%;
    }
    @media screen and (max-width: 760px) {
      max-width: unset;
      width: 100%;
    }
  }

  .blendguide {
    @include flexColumn($alignX: center);
    background-color: $secondary;
    border-radius: 0 0 20px 20px;

    margin-top: -20px;
    min-height: 88px;
    padding-top: 44px;
    width: 96%;

    svg {
      fill: $primary;
      padding-top: 4px;
    }

    label {
      @include flexRow($alignY: center);
      color: $primary;
      cursor: pointer;
      font-size: $fontHeading3;
      margin-bottom: 24px;
      @media screen and (max-width: 768px) {
        font-size: $fontHeading4;
      }
    }
  }

  .currentLink {
    @include flexColumn($alignX: center);
    background-color: $secondary;
    border-radius: 0 0 20px 20px;
    margin-top: -20px;
    min-height: 88px;
    padding-top: 44px;
    width: 96%;
    color: $primary;
    cursor: pointer;
    font-size: $fontHeading3;
    margin-bottom: 24px;
  }
}
